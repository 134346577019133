<template>
  <div class="max-w-12xl mx-auto text-left catalog-search-wrapper">

    <div class="grid grid-cols-12 gap-4 md:h-full">
      <div class="college-search flex flex-col justify-between col-span-12 md:col-span-6 lg:col-span-3">
        <div class="bg-white sm:rounded-lg">
          <div class="px-4 py-5 sm:px-6 sm:py-3">
            <header class="mb-6 bg-white pt-2 flex flex-row">
              <div class="flex-auto">
                <h1 class="text-3xl font-extrabold mb-1">Quick Search</h1>
                <div class="text-sm">
                  Search and view college catalogs.
                </div>
                <a :href="urls.collegeSearch" class="text-xs underline text-blue-800 hover:text-blue-500" @click="trackCollegeSearchJump">
                  Jump to College Search
                </a>
              </div>
              <div class="flex-none">
                <!-- <UserMenu /> -->
              </div>
            </header>

            <div class="flex flex-col">

              <form>
                <div v-if="!isSingleCatalogPage" class="field-group mb-4">
                  <label for="states" class="block mb-2 text-sm font-medium text-gray-900">Select a
                    state:</label>
                  <select v-model="selectedState" @change="onStateChange" id="states"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    <option value="">Select a state</option>
                    <option v-for="state in states" :value="state.code" :key="state.code">{{ state.name }}</option>
                  </select>
                </div>

                <div v-if="!isSingleCatalogPage && colleges && colleges.length" class="field-group mb-4">
                  <label for="colleges" class="block mb-2 text-sm font-medium text-gray-900">Select a
                    college:</label>
                  <select v-model="selectedCollege" @change="onCollegeChange" id="colleges"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    <option value="">Select a college</option>
                    <option v-for="college in colleges" :value="college.id" :key="college.id">{{ college.institutionName
                      }}</option>
                  </select>
                </div>
                <div v-if="!isSingleCatalogPage && selectedState && !colleges" class="field-group">
                  <p>No colleges found.</p>
                </div>

                <div v-if="!isSingleCatalogPage && catalogs && catalogs.length" class="field-group">
                  <label for="catalogs" class="block mb-2 text-sm font-medium text-gray-900">Select a
                    catalog:</label>
                  <select v-if="!isSingleCatalogPage && catalogs && catalogs.length" v-model="selectedCatalog"
                    @change="onCatalogChange" id="catalogs"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4">
                    <option selected value="">Select a catalog</option>
                    <option v-for="catalog in catalogs" :value="catalog" :key="catalog.id">{{ catalog.edition }}
                    </option>
                  </select>

                  <!-- Web Catalog Link -->
                  <a v-if="selectedCatalogFormat === 'Web'" :href="selectedCatalogUrl" target="_blank"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center">
                    View this Web Catalog &nbsp;
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                      fill="none" viewBox="0 0 14 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                  </a>
                </div>

              </form>

              <!-- Search UI -->
              <form @submit.prevent="executeSearch" v-if="selectedCatalogFormat === 'PDF'">
                <div class="search-ui mb-2">
                  <label for="search" class="block mb-2 text-sm font-medium text-gray-900">Search this
                    catalog:</label>
                  <input id="search" v-model="searchTerm" type="text" placeholder="Search in PDF"
                    class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">

                  <button @click="executeSearch" type="submit"
                    class="mr-2 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg px-3 py-2 text-xs font-medium text-center">
                    <template v-if="searchStatus === 'IN_PROGRESS'">
                      <svg class="animate-spin inline -ml-1 mr-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                        </circle>
                        <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                      </svg>
                    </template>
                    Search
                  </button>

                  <span v-if="selectedCatalogFormat === 'PDF' && searchPerformed">
                    <button @click="searchPrevious" type="button"
                      class="mr-2 text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg px-3 py-2 text-xs font-medium text-center">Previous</button>

                    <button @click="searchNext" type="button"
                      class="mr-2 text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg px-3 py-2 text-xs font-medium text-center">Next</button>

                    <button @click="searchClear" type="button"
                      class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg px-3 py-2 text-xs font-medium text-center">Clear</button>
                  </span>

                </div>
              </form>

              <!-- Search Results -->
              <div class="search-results" v-if="selectedCatalogFormat === 'PDF' && searchPerformed">
                <p class="block mb-2 text-sm font-medium text-gray-900" v-if="currentResult">Current
                  Page: {{ currentResult.pageNumber }}</p>
                <p class="block mb-2 text-sm font-medium text-gray-900">Total Results: {{ totalResults
                  }}</p>
                <!-- Add navigation for next and previous results if needed -->
              </div>

            </div>
          </div>
        </div>


        <footer class="bg-white py-4 px-6 border-t-1 border-solid border-indigo-500 flex flex-col md:flex-row">
          <a href="https://degreedata.com" target="_blank">
            <img src="https://degreedata.com/wp-content/uploads/2020/12/degreedata_logo.png" alt="DegreeData Logo"
              class="w-40 flex-none mb-3" />
          </a>
          <div class="flex-auto flex justify-between flex-row md:flex-col md:text-right text-sm">
            <span>
              &copy; 2023 DegreeData, LLC
            </span>
            <a href="https://degreedata.com/privacy-policy/" class="underline text-right">
              Privacy Policy
            </a>
          </div>
        </footer>
      </div>

      <div class="course-details relative h-full col-span-12 md:col-span-6 lg:col-span-9">
        <div class="w-full mt-0 h-full">
          <div class="bg-white h-full">
            <div class="h-full">
              <!-- PDF Viewer or iFrame based on the selected catalog -->
              <div id="adobe-dc-view" class="h-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="max-w-2xl mx-auto"></div>


  </div>
</template>

<script>
export default {
  /* global AdobeDC */


  data() {
    return {
      urls: {
        collegeSearch: process.env.VUE_APP_COLLEGE_SEARCH_URL
      },
      selectedCollegeName: '',
      selectedCatalogYear: '',
      selectedPdf: null,
      adobeViewer: null,
      searchTerm: '',
      searchStatus: null,
      isSingleCatalogPage: false,
      searchResults: [],
      searchPerformed: false,
      currentResult: null,
      totalResults: 0,
      selectedState: '',
      selectedCollege: '',
      colleges: [],
      catalogs: [],
      selectedCatalog: null,
      selectedCatalogFormat: '',
      selectedCatalogUrl: '',
      states: [
        // Populate this with state names and codes
        { name: 'Alabama', code: 'AL' },
        { name: 'Alaska', code: 'AK' },
        { name: 'Arizona', code: 'AZ' },
        { name: 'Arkansas', code: 'AR' },
        { name: 'California', code: 'CA' },
        { name: 'Colorado', code: 'CO' },
        { name: 'Connecticut', code: 'CT' },
        { name: 'Delaware', code: 'DE' },
        { name: 'District of Columbia', code: 'DC' },
        { name: 'Florida', code: 'FL' },
        { name: 'Georgia', code: 'GA' },
        { name: 'Hawaii', code: 'HI' },
        { name: 'Idaho', code: 'ID' },
        { name: 'Illinois', code: 'IL' },
        { name: 'Indiana', code: 'IN' },
        { name: 'Iowa', code: 'IA' },
        { name: 'Kansas', code: 'KS' },
        { name: 'Kentucky', code: 'KY' },
        { name: 'Louisiana', code: 'LA' },
        { name: 'Maine', code: 'ME' },
        { name: 'Maryland', code: 'MD' },
        { name: 'Massachusetts', code: 'MA' },
        { name: 'Michigan', code: 'MI' },
        { name: 'Minnesota', code: 'MN' },
        { name: 'Mississippi', code: 'MS' },
        { name: 'Missouri', code: 'MO' },
        { name: 'Montana', code: 'MT' },
        { name: 'Nebraska', code: 'NE' },
        { name: 'Nevada', code: 'NV' },
        { name: 'New Hampshire', code: 'NH' },
        { name: 'New Jersey', code: 'NJ' },
        { name: 'New Mexico', code: 'NM' },
        { name: 'New York', code: 'NY' },
        { name: 'North Carolina', code: 'NC' },
        { name: 'North Dakota', code: 'ND' },
        { name: 'Ohio', code: 'OH' },
        { name: 'Oklahoma', code: 'OK' },
        { name: 'Oregon', code: 'OR' },
        { name: 'Pennsylvania', code: 'PA' },
        { name: 'Rhode Island', code: 'RI' },
        { name: 'South Carolina', code: 'SC' },
        { name: 'South Dakota', code: 'SD' },
        { name: 'Tennessee', code: 'TN' },
        { name: 'Texas', code: 'TX' },
        { name: 'Utah', code: 'UT' },
        { name: 'Vermont', code: 'VT' },
        { name: 'Virginia', code: 'VA' },
        { name: 'Washington', code: 'WA' },
        { name: 'West Virginia', code: 'WV' },
        { name: 'Wisconsin', code: 'WI' },
        { name: 'Wyoming', code: 'WY' }
      ],
    };
  },
  beforeMount() {

  const currentHost = window.location.hostname;
  
  // Redirect logic
  if (currentHost === 'curriculumsearch.degreedata.com') {
    window.location.href = 'https://quicksearch.degreedata.com' + window.location.pathname + window.location.search;
    return; // Stop further execution
  }

    const searchParams = new URLSearchParams(window.location.search);
    const catalogId = searchParams.get('catalogId');

    if (catalogId) {
      this.fetchCatalog(catalogId).then((catalog) => {
        if (catalog) {
          this.isSingleCatalogPage = true;
          this.selectedCatalog = catalog;
        }
      })
    }
  },
  methods: {
    trackCollegeSearchJump() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'college_search_jump',
        sourceApp: 'QuickSearch',
        destinationApp: 'CollegeSearch',
      });
    },

    async fetchCatalog(catalogId) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/catalogs?id=${catalogId}`);
        const data = await response.json();

        return data;
      } catch (e) {
        return null;
      }
    },

    async onStateChange() {
      this.colleges = [];
      this.selectedCollege = '';

      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/institutions/state?id=${this.selectedState}`);
        let data = await response.json();

        // Sorting colleges alphabetically by institutionName
        data.sort((a, b) => a.institutionName.localeCompare(b.institutionName));

        this.colleges = data;
      } catch (error) {
        console.error('Error fetching colleges:', error);
      }
    },

    async onCollegeChange() {
      if (this.selectedCollege) {
        const selectedCollegeObj = this.colleges.find(college => college.id === this.selectedCollege);
        this.selectedCollegeName = selectedCollegeObj ? selectedCollegeObj.institutionName : '';

        try {
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/catalogs/institution?id=${this.selectedCollege}`);
          let data = await response.json();

          // Sorting catalogs alphabetically by edition
          data.sort((a, b) => a.edition.localeCompare(b.edition));

          this.catalogs = data;

          this.selectedCatalog = null;
          this.selectedCatalogFormat = '';
          this.selectedCatalogUrl = '';
        } catch (error) {
          console.error('Error fetching catalogs:', error);
        }
      } else {
        this.catalogs = [];
      }
    },

    async onCatalogChange() {
      if (!this.selectedCatalog) return;

      const selectedCatalogObj = this.catalogs.find(catalog => catalog === this.selectedCatalog);
      this.selectedCatalogYear = selectedCatalogObj ? selectedCatalogObj.edition : '';

      this.$nextTick(async () => {
        try {
          if (this.selectedCatalog.format === 'PDF') {
            this.selectedCatalogFormat = 'PDF';
            this.loadPdf(`${process.env.VUE_APP_S3_BASE_URL}/${this.selectedCatalog.s3Id}`);
          } else if (this.selectedCatalog.format === 'Web') {
            this.selectedCatalogFormat = 'Web';
            this.selectedCatalogUrl = this.selectedCatalog.catalogUrl;
          }

          // Push event to dataLayer
          window.dataLayer.push({
            event: 'catalog_selected',
            state: this.selectedState,
            college: this.selectedCollegeName,
            catalogYear: this.selectedCatalogYear,
          });

        } catch (error) {
          console.error('Error loading PDF:', error);
        }
      });
    },

    loadPdf(url) {
      this.$nextTick(() => {
        this.selectedPdf = url;

        const viewerConfig = {
          defaultViewMode: "FIT_WIDTH",
          showAnnotationTools: false,
          showDownloadPDF: false,
          showPrintPDF: false,
          enableSearchAPIs: true
        };

        const adobeDCView = new AdobeDC.View({
          clientId: process.env.VUE_APP_ADOBE_CLIENT_ID,
          divId: "adobe-dc-view",
        });

        adobeDCView.previewFile({
          content: {
            location: { url: this.selectedPdf },
          },
          metaData: {
            fileName: `${this.selectedCollegeName} - ${this.selectedCatalogYear}.pdf`,
          },
        }, viewerConfig).then(adobeViewer => {
          this.adobeViewer = adobeViewer;
        });

        // Add focus tracking for the PDF viewer
        const pdfViewElement = document.getElementById('adobe-dc-view');
        pdfViewElement.addEventListener('focus', () => {
          window.dataLayer.push({
            event: 'pdf_view_focus',
            catalogYear: this.selectedCatalogYear,
            college: this.selectedCollegeName,
            state: this.selectedState,
          });
        });
      });
    },

    executeSearch() {
      if (!this.adobeViewer || this.searchTerm.length === 0) {
        return;
      }

      window.dataLayer.push({
        event: 'catalog_search',
        searchTerm: this.searchTerm,
        catalogYear: this.selectedCatalogYear,
        college: this.selectedCollegeName,
        state: this.selectedState,
      });

      this.adobeViewer.getAPIs().then(apis => {
        apis.search(this.searchTerm).then(searchObject => {
          this.searchObject = searchObject;
          searchObject.onResultsUpdate(this.onResultsUpdate).catch(this.onSearchFail);
          this.searchPerformed = true;
        }).catch(this.onSearchFail);
      });
    },

    // Method to handle search results update
    onResultsUpdate(result) {
      console.log(result);

      this.searchStatus = result.status;
      this.currentResult = result.currentResult;
      this.totalResults = result.totalResults;
    },

    // Method to handle search failures
    onSearchFail(error) {
      console.error('Search failed:', error);
    },

    // Additional methods for navigating search results
    searchNext() {
      if (this.searchObject) {
        this.searchObject.next();
      }
    },

    searchPrevious() {
      if (this.searchObject) {
        this.searchObject.previous();
      }
    },

    searchClear() {
      if (this.searchObject) {
        this.searchObject.clear();
      }
      this.searchTerm = '';
      this.searchPerformed = false;
      this.searchStatus = null;
    },

  },

  watch: {
    selectedCollege(newVal) {
      if (newVal) {
        this.onCollegeChange();
      }
    },
    selectedCatalog(newVal) {
      if (newVal) {
        this.onCatalogChange();
      }
    },
  },
};
</script>

<style>
#app {
  font-family: "Inter var", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.catalog-search-wrapper {
  height: calc(100vh);
}

h1 {
  font-family: "Inter var", Helvetica, Arial, sans-serif;
}

h2 {
  font-family: "Inter var", Helvetica, Arial, sans-serif;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
